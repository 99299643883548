import { CURRENCY_CHF, PAYMENT_METHOD_PAYSAFECARD } from '../variables';

export const formatCurrency = (amount = 0, currency, limit = 2) => {
    const formattedAmount = parseFloat(String(amount).replace(/,/g, '')).toFixed(limit).toLocaleString();

    switch (currency) {
    case 'EUR':
        return `${formattedAmount} €`;
    case 'CAD':
    case 'USD':
        return `$${formattedAmount}`;
    case 'INR':
        return `₹${formattedAmount}`;
    case null:
        return formattedAmount;
    default:
        return `${currency} ${formattedAmount}`;
    }
};

export const formatCurrencySymbol = (currency) => {
    switch (currency) {
    case 'EUR':
        return '€';
    case 'CAD':
    case 'USD':
        return '$';
    case 'INR':
        return '₹';
    default:
        return currency;
    }
};

export const formatBannerCurrency = (amount = 0, currency, limit = 0) => {
    const formattedAmount = parseFloat(String(amount).replace(/,/g, '')).toFixed(limit).toLocaleString();

    switch (currency) {
    case 'CAD':
    case 'USD':
        return `$${formattedAmount}`;
    case 'CHF':
        return `${currency} ${formattedAmount}`;
    default:
        return `${formattedAmount}€`;
    }
};

export const getAmountRange = (paymentMethod, currency) => {
    const defaultRange = ['50', '100', '500'];

    const rangeOverrides = {
        [PAYMENT_METHOD_PAYSAFECARD]: {
            [CURRENCY_CHF]: ['50', '100', '300'],
        },
    };

    return rangeOverrides[paymentMethod]?.[currency] || defaultRange;
};
