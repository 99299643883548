import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { COUNTRY_CODE_FR } from '../variables';

export const useSupportWidget = () => {
    const { loc } = useSelector((state) => state.global.clientIp);
    const { isAuth, information: userInformation } = useSelector((state) => state.user);
    const [isZendeskInit, setIsZendeskInit] = useState(false);

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                if (typeof window.zE === 'function') {
                    // Make widget hidden by default
                    window.zE('webWidget', 'hide');
                    setIsZendeskInit(true);
                    observer.disconnect();
                }
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        return () => observer.disconnect();
    }, []);

    // Sends user data
    useEffect(() => {
        if (isZendeskInit) {
            if (isAuth && userInformation.id) {
                const { firstName, lastName, email, countryPhoneCodePrefix, phoneNumber, vipLevel } = userInformation;
                const info = {
                    name: `${firstName} ${lastName}`,
                    email: email,
                    brand: 'Wild Sultan',
                    phone: `${countryPhoneCodePrefix}${phoneNumber}`
                };

                const isValidZopimEnvironment = () => typeof window.zE.identify === 'function';

                const addVIPTag = () => {
                    if (vipLevel?.rank) {
                        window.zE('webWidget', 'chat:addTags', [`WS_VIP_${vipLevel.rank}`]);
                    }
                };

                const sendUserDataToZe = () => {
                    window.zE.identify(info);
                    addVIPTag();
                };

                if (isValidZopimEnvironment()) {
                    sendUserDataToZe();
                } else {
                    const listener = (e) => {
                        if (
                            e.key === '__zlcstore' &&
                            typeof window.$zopim === 'function' &&
                            isValidZopimEnvironment()
                        ) {
                            sendUserDataToZe();
                        }
                    };

                    window.addEventListener('storage', listener);

                    return () => window.removeEventListener('storage', listener);
                }
            }
        }
    }, [userInformation.id, isZendeskInit]);

    // Hides widget for unlogged users from France
    useEffect(() => {
        if (isZendeskInit) {
            if (!isAuth && loc === COUNTRY_CODE_FR) {
                window.zE('webWidget', 'hide');
            } else {
                window.zE('webWidget', 'show');
            }
        }
    }, [isAuth, loc, isZendeskInit]);
};
