import { kycWorkers } from '../../services/http';
import { documentConfig } from '../../data/kycData';
import {
    COOKIE_NAME_TOKEN,
    DOCUMENT_STATUS_TO_VALIDATE,
    DOCUMENT_STATUS_VALIDATED,
    NOTIFICATIONS_STATUS_ERROR,
    NOTIFICATIONS_STATUS_SUCCESS
} from '../../variables';
import { setDomainCookie } from '../../utils/cookieConfig';

const SET_KYC_MODAL_OPENED = 'SET_KYC_MODAL_OPENED';
const SET_KYC_ACTIVE_STEP = 'SET_KYC_ACTIVE_STEP';
const SET_KYC_CURRENT_VIEW = 'SET_KYC_CURRENT_VIEW';
const SET_ACTIVE_DOCUMENT_CATEGORY = 'SET_ACTIVE_DOCUMENT_CATEGORY';
const SET_SELECTED_DOCUMENT_TYPE = 'SET_SELECTED_DOCUMENT_TYPE';
const SET_FILES = 'SET_FILES';
const RESET_FILES = 'RESET_FILES';
const SET_PREVIEW_SLOT = 'SET_PREVIEW_SLOT';
const FETCH_USER_DOCS = 'FETCH_USER_DOCS';
const FETCH_USER_DOCS_SUCCESS = 'FETCH_USER_DOCS_SUCCESS';
const FETCH_USER_DOCS_FAIL = 'FETCH_USER_DOCS_FAIL';
const UPLOAD_FILES = 'UPLOAD_FILES';
const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
const UPLOAD_FILES_FAILED = 'UPLOAD_FILES_FAILED';
const RESET_UPLOAD_FILES = 'RESET_UPLOAD_FILES';
const INIT_KYC_STATE = 'INIT_KYC_STATE';
const SET_NOTIFICATION_UPDATE_STATUS = 'SET_NOTIFICATION_UPDATE_STATUS';
const RESET_NOTIFICATION_UPDATE_STATUS = 'RESET_NOTIFICATION_UPDATE_STATUS';

const {
    validateDocumentRequest,
    uploadDocumentsRequest,
    fetchUserDocuments
} = kycWorkers;

export const initialState = {
    isKycModalOpened: false,
    isKycModalClosePrevented: false,
    activeDocumentCategory: {
        name: '',
        documentsToUpload: []
    },
    kycActiveStep: 1,
    kycCurrentView: '',
    selectedDocumentType: '',
    files: {},
    previewSlot: '',
    documentsLoading: false,
    documents: [],
    documentsError: null,
    uploadStatus: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    notificationUpdateStatus: '',
    notificationUpdateDocumentType: '',
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case SET_KYC_MODAL_OPENED:
        return {
            ...state,
            isKycModalOpened: action.payload
        };
    case SET_KYC_ACTIVE_STEP:
        return {
            ...state,
            kycActiveStep: action.payload
        };
    case SET_KYC_CURRENT_VIEW:
        return {
            ...state,
            kycCurrentView: action.payload
        };
    case SET_ACTIVE_DOCUMENT_CATEGORY:
        return {
            ...state,
            activeDocumentCategory: action.payload
        };
    case SET_SELECTED_DOCUMENT_TYPE:
        return {
            ...state,
            selectedDocumentType: action.payload
        };
    case SET_FILES:
        return {
            ...state,
            files: {
                ...state.files,
                ...action.payload
            },
            isKycModalClosePrevented: action.isClosePrevented
        };
    case RESET_FILES:
        return {
            ...state,
            files: {},
            isKycModalClosePrevented: false
        };
    case SET_PREVIEW_SLOT:
        return {
            ...state,
            previewSlot: action.payload
        };
    case FETCH_USER_DOCS:
        return {
            ...state,
            documentsLoading: true
        };
    case FETCH_USER_DOCS_SUCCESS:
        return {
            ...state,
            documents: action.payload.data.document,
            documentsLoading: false
        };
    case FETCH_USER_DOCS_FAIL:
        return {
            ...state,
            documentsError: action.payload,
            documentsLoading: false
        };
    case UPLOAD_FILES:
        return {
            ...state,
            uploadStatus: {
                isLoading: true,
                isSuccess: false,
                isError: false
            },
            isKycModalClosePrevented: true
        };
    case UPLOAD_FILES_SUCCESS:
        return {
            ...state,
            uploadStatus: {
                isLoading: false,
                isSuccess: true,
                isError: false
            },
            isKycModalClosePrevented: false
        };
    case UPLOAD_FILES_FAILED:
        return {
            ...state,
            uploadStatus: {
                isLoading: false,
                isSuccess: false,
                isError: true
            },
            isKycModalClosePrevented: false
        };
    case RESET_UPLOAD_FILES:
        return {
            ...state,
            uploadStatus: {
                isLoading: false,
                isSuccess: false,
                isError: false
            },
            isKycModalClosePrevented: false
        };
    case SET_NOTIFICATION_UPDATE_STATUS:
        return {
            ...state,
            notificationUpdateStatus: action.payload.toStatus,
            notificationUpdateDocumentType: action.payload.documentType,
            documents: action.documents
        };
    case RESET_NOTIFICATION_UPDATE_STATUS:
        return {
            ...state,
            notificationUpdateStatus: ''
        };
    case INIT_KYC_STATE:
        return initialState;
    default:
        return state;
    }
};

export const setKycModalOpened = (payload) => ({
    type: SET_KYC_MODAL_OPENED,
    payload
});

export const setKycActiveStep = (payload) => ({
    type: SET_KYC_ACTIVE_STEP,
    payload
});

export const setKycCurrentView = (payload) => ({
    type: SET_KYC_CURRENT_VIEW,
    payload
});

export const setActiveDocumentCategory = (name) => {
    return (dispatch, getState) => {
        const getPayload = () => {
            if (name) {
                const { documents } = getState().kyc;
                const uploadedDocumentTypes = documents
                    .filter((item) => [DOCUMENT_STATUS_TO_VALIDATE, DOCUMENT_STATUS_VALIDATED].includes(item.status))
                    .map((item) => item.type);
                const documentsToUpload = Object.keys(documentConfig[name])
                    .filter(item =>
                        !(uploadedDocumentTypes.includes(item) && !documentConfig[name][item].isMultipleAvailable)
                    );

                return {
                    name,
                    documentsToUpload
                };
            }

            return {
                name,
                documentsToUpload: []
            };
        };

        dispatch({
            type: SET_ACTIVE_DOCUMENT_CATEGORY,
            payload: getPayload()
        });
    };
};

export const setSelectedDocumentType = (payload) => ({
    type: SET_SELECTED_DOCUMENT_TYPE,
    payload
});

export const setFiles = (payload, isClosePrevented = false) => ({
    type: SET_FILES,
    payload,
    isClosePrevented
});

export const resetFiles = () => ({
    type: RESET_FILES
});

export const setPreviewSlot = (payload) => ({
    type: SET_PREVIEW_SLOT,
    payload
});

export const onCloseKycModal = () => {
    return dispatch => {
        dispatch(setKycActiveStep(1));
        dispatch(setKycCurrentView(''));
        dispatch(setActiveDocumentCategory(''));
        dispatch(setSelectedDocumentType(''));
        dispatch(setPreviewSlot(''));
        dispatch(resetFiles());
    };
};

const fileValidationFailed = (slotName, error) => {
    return (dispatch) => {
        dispatch(setFiles({
            [slotName]: {
                file: null,
                validator: {
                    isLoading: false,
                    status: NOTIFICATIONS_STATUS_ERROR,
                    text: error
                }
            }
        }));
    };
};

export const validateFile = (file, type, slotName) => {
    const maximumFileSize = 10485760;
    const minImageWidth = 255;
    const minImageHeight = 175;
    const divider = 1000000;
    const getSizeText = (size) => `${(size / divider).toFixed(3)} MB`;
    const acceptedFormat = ['png', 'jpg', 'jpeg', 'pdf'];

    const isFormatAccepted = acceptedFormat.some((element) => {
        return !!file?.type?.includes(element);
    });

    return (dispatch, getState) => {
        const reader = new FileReader();
        const tr = getState().global.data.translations;

        const errors = {
            getSizeError: (fileSize) =>
                `${tr['document_upload_error_filesize_1']} (${getSizeText(fileSize)}).
                ${tr['document_upload_error_filesize_2']} ${getSizeText(maximumFileSize)}.`,
            getDimensionsError: () => `${tr['document_upload_error_img_size']}: 255x175px`,
            getFileTypeError: () => tr['document_upload_error_invalid_type'],
            getGenericError: () => tr['profile.kyc.modal.uploader.generic_error_message']
        };

        //prevent loading files with invalid size
        const validateFileSize = () => {
            file.size > maximumFileSize
                ? dispatch(fileValidationFailed(slotName, errors.getSizeError(file.size)))
                : dispatch(validateFileApi(file, type, slotName, errors));
        };

        //prevent loading invalid types and images with invalid dimensions
        reader.onload = (e) => {
            if (isFormatAccepted) {
                if (file.type.includes('image')) {
                    let img = new Image();
                    img.src = e.target.result;

                    img.onload = function () {
                        const isInvalidSize = this.width < minImageWidth || this.height < minImageHeight;
                        isInvalidSize ?
                            dispatch(fileValidationFailed(slotName, errors.getDimensionsError())) :
                            validateFileSize();
                    };
                } else validateFileSize();
            } else {
                dispatch(fileValidationFailed(slotName, errors.getFileTypeError()));
            }
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };
};

export const validateFileApi = (file, type, slotName, errors) => {
    const data = new FormData();
    data.append('file', file);
    data.append('type', type);

    return (dispatch) => {
        dispatch(setFiles({
            [slotName]: {
                file: null,
                validator: {
                    isLoading: true,
                    status: '',
                    text: ''
                }
            }
        }, true));
        validateDocumentRequest(data)
            .then(() => {
                dispatch(setFiles({
                    [slotName]: {
                        file,
                        validator: {
                            isLoading: false,
                            status: NOTIFICATIONS_STATUS_SUCCESS,
                            text: ''
                        }
                    }
                }));
            })
            .catch((err) => {
                const error = err || {};
                const getErrorByStatus = () => {
                    switch (error.status) {
                    case 413:
                        return errors.getSizeError(file.size);
                    case 415:
                        return errors.getFileTypeError();
                    case 422:
                        return errors.getDimensionsError();
                    default:
                        return errors.getGenericError();
                    }
                };
                dispatch(fileValidationFailed(slotName, getErrorByStatus()));
            });
    };
};

export const getUserDocuments = () => {
    const loading = () => ({
        type: FETCH_USER_DOCS
    });
    const success = (payload) => ({
        type: FETCH_USER_DOCS_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: FETCH_USER_DOCS_FAIL,
        payload
    });
    return (dispatch) => {
        dispatch(loading());
        fetchUserDocuments()
            .then((res) => {
                const token = res.data.authorization.token;
                setDomainCookie(COOKIE_NAME_TOKEN, token);
                dispatch(success(res.data));
            })
            .catch((err) => dispatch(fail(err)));
    };
};

export const uploadFiles = (data) => {
    const loading = () => ({
        type: UPLOAD_FILES
    });
    const success = () => ({
        type: UPLOAD_FILES_SUCCESS
    });
    const fail = () => ({
        type: UPLOAD_FILES_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        uploadDocumentsRequest(data)
            .then(() => {
                dispatch(success());
                dispatch(getUserDocuments());
            })
            .catch(() => dispatch(fail()))
            .finally(() => dispatch(setKycModalOpened(false)));
    };
};

export const resetUploadFiles = () => ({
    type: RESET_UPLOAD_FILES
});

export const initKycState = () => ({
    type: INIT_KYC_STATE
});

export const setNotificationUpdateStatus = ({ documentId, documentType, toStatus }) => {
    return (dispatch, getState) => {
        const documents = getState().kyc.documents.map(item => {
            if (item.id === documentId) {
                return {
                    ...item,
                    status: toStatus
                };
            }

            return item;
        });

        return dispatch({
            type: SET_NOTIFICATION_UPDATE_STATUS,
            payload: {
                documentType,
                toStatus
            },
            documents
        });
    };
};

export const resetNotificationUpdateStatus = () => ({
    type: RESET_NOTIFICATION_UPDATE_STATUS
});
