import { combineReducers } from 'redux';
import global from './global';
import games from './games';
import user from './user';
import register from './register';
import slider from './slider';
import bank from './bank';
import bonus from './bonus';
import promotions from './promotions';
import prefooter from './prefooter';
import wheel_of_fortune from './wheel-of-fortune';
import vip from './vip';
import mystery_chests from './mystery-chests';
import kyc from './kyc';
import jackpot from './jackpot';

export default combineReducers({
    global,
    games,
    user,
    register,
    slider,
    bank,
    bonus,
    promotions,
    prefooter,
    wheel_of_fortune,
    vip,
    mystery_chests,
    kyc,
    jackpot
});
