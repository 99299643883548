import dayjs from 'dayjs';
import getCookie from '@tlf-e/brand-utils/dist/helpers/cookieConfig/getCookie';
import { clearDomainCookie } from '../utils/cookieConfig';
import { COOKIE_NAME_SUSPENDED_CHECKING } from '../variables';
import { AppStore } from './http';

// Prevent importing modules each time we execute functions
const storeModules = {
    globalStoreModule: null,
    registerStoreModule: null,
    userStoreModule: null
};

export const setStoreTokenTime = async () => {
    if (!storeModules.globalStoreModule) {
        storeModules.globalStoreModule = await import('../store/reducers/global');
    }
    AppStore.store.dispatch(storeModules.globalStoreModule.setTokenTime(dayjs().unix()));
};

export const handleUnauthorizedError = async () => {
    if (getCookie(COOKIE_NAME_SUSPENDED_CHECKING)) {
        if (!storeModules.registerStoreModule) {
            storeModules.registerStoreModule = await import('../store/reducers/register');
        }
        AppStore.store.dispatch(storeModules.registerStoreModule.setIsSuspended(true));
        clearDomainCookie(COOKIE_NAME_SUSPENDED_CHECKING);
    }

    if (!storeModules.userStoreModule) {
        storeModules.userStoreModule = await import('../store/reducers/user');
    }
    AppStore.store.dispatch(storeModules.userStoreModule.logoutUserSuccess());
};
