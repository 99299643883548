import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import cx from 'classnames';
import dataLayerPush from '@tlf-e/brand-utils/dist/helpers/dataLayerPush';
import handleIframeExpand from '@tlf-e/brand-utils/dist/helpers/expandConfig/handleIframeExpand';
import isFullScreenAvailable from '@tlf-e/brand-utils/dist/helpers/expandConfig/isFullScreenAvailable';
import { setIsFavoriteGameModalOpened } from '../../../../../store/reducers/games';
import { withGameActions } from '../../../../hoc/withGameActions';
import { setGameOverlayActive, setGameOverlayMinimized } from '../../../../../store/reducers/global';
import {
    ANIMATION_COMPLETED,
    BTN_ID_CLOSE,
    BTN_ID_EXPAND,
    BTN_ID_FAVORITE,
    BTN_ID_MINIMIZE,
    GAME_INTERFACE_CLOSE,
    GAME_INTERFACE_FAVORITE_OPEN,
    GAME_INTERFACE_FULLSCREEN,
    GAME_INTERFACE_PIP_MIN
} from '../../../../../variables';
import FavoriteInactive from '../../../../svg-icons/game-controls/FavoriteInactive';
import FavoriteActive from '../../../../svg-icons/game-controls/FavoriteActive';
import CloseGameIcon from '../../../../svg-icons/game-controls/CloseGameIcon';
import ExpandScreen from '../../../../svg-icons/game-controls/ExpandScreen';
import ReduceScreen from '../../../../svg-icons/game-controls/ReduceScreen';
import Minimized from '../../../../svg-icons/Minimized';
import routes from '../../../../../data/routes.json';
import ActionsTooltip from './fragment/ActionsTooltip';

const Actions = ({ rightIndent, handleClose, isFavorite, game, playRef }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const isAuth = useSelector((state) => state.user.isAuth);
    const { favoriteGamesLoading, isFavoriteGameModalOpened, isFullscreen } = useSelector((state) => state.games);
    const isFavoriteIconActive = isFavoriteGameModalOpened || isFavorite;
    const isEvolution = game.isEvolution;
    const isFullScreenEnabled = isFullScreenAvailable();
    const [animationCompleted, setAnimationCompleted] = useState(false);
    const [animatedIndex, setAnimatedIndex] = useState([]);
    const [animatedButtonId, setAnimatedButtonId] = useState(null);
    const [isHovered, setIsHovered] = useState('');
    const timeoutsRef = useRef([]);
    const dispatch = useDispatch();
    const router = useRouter();

    const handleMouseEnter = (e) => {
        const { id } = e.target;
        setIsHovered(id);
    };

    const handleMouseLeave = () => {
        setIsHovered('');
    };

    useEffect(() => {
        const isAnimationCompleted = !!localStorage.getItem(ANIMATION_COMPLETED);
        setAnimationCompleted(isAnimationCompleted);

        if (!isAnimationCompleted) {
            const buttons = document.querySelectorAll('.action');
            let delay = 0;

            buttons.forEach((button, index) => {
                const buttonId = button.id;
                const timeoutId = setTimeout(() => {
                    setAnimatedIndex((prevIndex) => [...prevIndex, buttonId]);
                    setAnimatedButtonId(buttonId);
                    const innerTimeoutId = setTimeout(() => {
                        setAnimatedButtonId(null);
                        if (index === buttons.length - 1) {
                            localStorage.setItem(ANIMATION_COMPLETED, true);
                            setAnimationCompleted(true);
                            setAnimatedIndex([]);
                        }
                    }, 7000);
                    timeoutsRef.current.push(innerTimeoutId);
                }, delay);
                timeoutsRef.current.push(timeoutId);
                delay += 1000;
            });

            return () => {
                timeoutsRef.current.forEach((timeoutId) => clearTimeout(timeoutId));
                dispatch(setIsFavoriteGameModalOpened(false));
            };
        }
    }, []);

    const handleCloseGame = () => {
        handleClose();
        dataLayerPush({ event: GAME_INTERFACE_CLOSE, game: game.name });
    };

    const handleExpand = () => {
        handleIframeExpand(playRef);
        dispatch(setIsFavoriteGameModalOpened(false));
        dataLayerPush({ event: GAME_INTERFACE_FULLSCREEN, game: game.name });
    };

    const handleOpenModal = () => {
        dispatch(setIsFavoriteGameModalOpened(true));
        dataLayerPush({ event: GAME_INTERFACE_FAVORITE_OPEN, game: game.name });
    };

    const handleMinimize = () => {
        dispatch(setGameOverlayMinimized(true));
        router.push(routes.home);
        dispatch(setGameOverlayActive(true));
        dataLayerPush({ event: GAME_INTERFACE_PIP_MIN, game: game.name });
    };

    const isTooltipOpen = (id) => {
        return isHovered === id || animatedIndex.includes(id);
    };

    return (
        <div className="actions" style={{ right: rightIndent }}>
            {isAuth && (
                <ActionsTooltip
                    title={tr['lobby.games.favorites']}
                    isOpen={isTooltipOpen(BTN_ID_FAVORITE)}
                    animationCompleted={animationCompleted}
                >
                    <button
                        id={BTN_ID_FAVORITE}
                        type="button"
                        data-testid={BTN_ID_FAVORITE}
                        className={cx('action', {
                            favorite: isFavoriteIconActive,
                            'opened-modal': isFavoriteGameModalOpened,
                            animated: animatedButtonId === BTN_ID_FAVORITE
                        })}
                        disabled={favoriteGamesLoading || !animationCompleted}
                        onClick={handleOpenModal}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {isFavoriteIconActive ? <FavoriteActive /> : <FavoriteInactive />}
                    </button>
                </ActionsTooltip>
            )}
            {!isEvolution && isFullScreenEnabled && (
                <ActionsTooltip
                    title={tr['actions_fullscreen']}
                    isOpen={isTooltipOpen(BTN_ID_EXPAND)}
                    animationCompleted={animationCompleted}
                >
                    <button
                        id={BTN_ID_EXPAND}
                        type="button"
                        className={cx('action expand', {
                            fullscreen: isFullscreen,
                            animated: animatedButtonId === BTN_ID_EXPAND
                        })}
                        data-testid={BTN_ID_EXPAND}
                        onClick={handleExpand}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        disabled={!animationCompleted}
                    >
                        {isFullscreen ? <ReduceScreen /> : <ExpandScreen />}
                    </button>
                </ActionsTooltip>
            )}
            {!isEvolution && !isFullscreen && (
                <ActionsTooltip
                    title={tr['actions_minimize']}
                    isOpen={isTooltipOpen(BTN_ID_MINIMIZE)}
                    animationCompleted={animationCompleted}
                >
                    <button
                        id={BTN_ID_MINIMIZE}
                        type="button"
                        className={cx('action minimize', {
                            animated: animatedButtonId === BTN_ID_MINIMIZE
                        })}
                        data-testid={BTN_ID_MINIMIZE}
                        onClick={handleMinimize}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        disabled={!animationCompleted}
                    >
                        <Minimized />
                    </button>
                </ActionsTooltip>
            )}
            <ActionsTooltip
                title={tr['draggable_widget_close_game']}
                isOpen={isTooltipOpen(BTN_ID_CLOSE)}
                animationCompleted={animationCompleted}
            >
                <button
                    id={BTN_ID_CLOSE}
                    type="button"
                    className={cx('action close_game_icon', {
                        fullscreen: isFullscreen,
                        animated: animatedButtonId === BTN_ID_CLOSE
                    })}
                    data-testid={BTN_ID_CLOSE}
                    onClick={handleCloseGame}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    disabled={!animationCompleted}
                >
                    <CloseGameIcon />
                </button>
            </ActionsTooltip>
        </div>
    );
};

export default withGameActions(Actions);
