import { useEffect, useRef, useState } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import GameCommunicator from '@tlf-e/brand-utils/dist/games/GameCommunicator';
import getPlayAndGoGame from '@tlf-e/brand-utils/dist/games/providers/getPlayAndGoGame';
import { useDispatch } from 'react-redux';
import { initGameLaunchContent } from '../../../../store/reducers/games';

const PlayandgoGame = ({ gameLaunchContent }) => {
    const containerIframeRef = useRef();
    const router = useRouter();
    const [scriptId] = useState(`playandgo-script`);
    const dispatch = useDispatch();
    const { isScriptExists, playandgoScript, iframeSrc } = getPlayAndGoGame(gameLaunchContent);

    useEffect(() => {
        resizeGeneratedIframe();
        containerIframeRef.current?.addEventListener('DOMSubtreeModified', resizeGeneratedIframe);
        return () => {
            containerIframeRef.current?.removeEventListener('DOMSubtreeModified', resizeGeneratedIframe);
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow = 'visible';

        return () => {
            dispatch(initGameLaunchContent());
            const iframe = document.getElementById('gameFrame');
            const script = document.getElementById(scriptId);
            if (iframe) {
                iframe.remove();
                iframe.contentWindow.onbeforeunload = function () {
                    GameCommunicator.close();
                };
            }
            script && script.remove();
            document.body.style.overflow = '';
            document.body.style.height = '';
        };
    }, []);

    const resizeGeneratedIframe = () => {
        const gameIframe = document.getElementById('gameFrame');
        if (gameIframe) {
            gameIframe.style.position = 'absolute';
            gameIframe.style.border = 'none';
            gameIframe.style.width = '100%';
            gameIframe.style.height = '100%';
        }
    };

    return (
        <div id="game" ref={containerIframeRef}>
            <iframe
                src={iframeSrc}
                id="gameFrame"
                onLoad={(e) => {
                    GameCommunicator.init(e.target, router.push);
                    GameCommunicator.postMessage({
                        messageType: 'addEventListener',
                        eventType: 'backToLobby'
                    });
                    resizeGeneratedIframe();
                }}
            />
            {isScriptExists && (
                <Script
                    id={scriptId}
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                        __html: playandgoScript
                    }}
                />
            )}
        </div>
    );
};

export default PlayandgoGame;
