import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from '../../ToastNotification';
import { resetNotificationUpdateStatus } from '../../../../store/reducers/kyc';
import { kycNotifications } from '../../../../data/kycData';

const UpdateDocumentStatusNotifications = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { notificationUpdateStatus, notificationUpdateDocumentType } = useSelector((state) => state.kyc);
    const dispatch = useDispatch();

    const onCloseUpdateStatusNotification = (e, reason) => {
        if (reason !== 'clickaway') {
            dispatch(resetNotificationUpdateStatus());
        }
    };

    return (
        Object.keys(kycNotifications).map(((item, index) => {
            const { status, picto } = kycNotifications[item];
            return (
                <ToastNotification
                    key={index}
                    isOpen={notificationUpdateStatus === item}
                    status={status}
                    onClose={onCloseUpdateStatusNotification}
                    picto={picto}
                    message={tr[`profile.kyc.update_status_notification.${item}`]?.replace(
                        '##DOCUMENT_TYPE##',
                        tr[`document.name.${notificationUpdateDocumentType}`]
                    )}
                />
            );
        }))
    );
};

export default UpdateDocumentStatusNotifications;
