import { GAME_MODE_DEMO, GAME_MODE_REAL } from '../variables';
import dataLayerPush from '@tlf-e/brand-utils/dist/helpers/dataLayerPush';
import getCategoryName from '@tlf-e/brand-utils/dist/helpers/commonConfig/getCategoryName/index';
import getVendorName from '@tlf-e/brand-utils/dist/helpers/commonConfig/getVendorName/index';
import { getGamePath } from './locationConfig';

export const gameImpressions = ({ eventName, displayedGames, gamesData, categories, softwares, isAuth, router }) => {
    const gameMode = isAuth ? GAME_MODE_REAL : GAME_MODE_DEMO;

    const products = displayedGames?.map((game) => ({
        gameId: game?.id,
        name: game?.name,
        tableId: game?.tableHash ?? null,
        demoAllowed: !game?.isDemoRestricted,
        category: getCategoryName(categories, game),
        vendor: getVendorName(softwares, game),
        image: game?.metadata?.thumbnailBackground,
        realDemo: gameMode,
        launchUrl: `${window.location.origin}${getGamePath(game, gamesData, router.locale)}`
    }));

    if (products.length > 0) {
        dataLayerPush({ event: eventName, products });
    }
};
