import Success from '../components/svg-icons/Success';
import AlertTriangle from '../components/svg-icons/AlertTriangle';
import {
    DOCUMENT_CATEGORY_ADDRESS,
    DOCUMENT_CATEGORY_IDENTITY,
    DOCUMENT_CATEGORY_OTHER,
    DOCUMENT_CATEGORY_PAYMENT_METHOD,
    DOCUMENT_NAME_BANK_ACCOUNT_IDENTITY,
    DOCUMENT_NAME_BANK_CARD,
    DOCUMENT_NAME_DRIVER_LICENSE,
    DOCUMENT_NAME_EWALLET,
    DOCUMENT_NAME_HOST_PROOF_OF_ADDRESS,
    DOCUMENT_NAME_ID_CARD,
    DOCUMENT_NAME_ID_DOCUMENT,
    DOCUMENT_NAME_OPEN_CHECKS,
    DOCUMENT_NAME_OTHER,
    DOCUMENT_NAME_PASSPORT,
    DOCUMENT_NAME_PROOF_OF_ADDRESS,
    DOCUMENT_NAME_PROOF_OF_PAYMENT_METHOD,
    DOCUMENT_NAME_RESIDENCE_CARD,
    DOCUMENT_NAME_RG_OTHERS,
    DOCUMENT_NAME_RG_SOW,
    DOCUMENT_NAME_SELFIE_WITH_ID_CARD,
    DOCUMENT_NAME_SOURCE_OF_WEALTH,
    DOCUMENT_NAME_SWORN_STATEMENT_BANK_CARD,
    DOCUMENT_NAME_SWORN_STATEMENT_RESIDENCE,
    DOCUMENT_SLOT_NAME_BACK_SIDE,
    DOCUMENT_SLOT_NAME_FRONT_SIDE,
    DOCUMENT_STATUS_EXPIRED,
    DOCUMENT_STATUS_REFUSED,
    DOCUMENT_STATUS_VALIDATED,
    NOTIFICATIONS_STATUS_ERROR,
    NOTIFICATIONS_STATUS_SUCCESS
} from '../variables';

export const documentConfig = {
    [DOCUMENT_CATEGORY_ADDRESS]: {
        [DOCUMENT_NAME_PROOF_OF_ADDRESS]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_SWORN_STATEMENT_RESIDENCE]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_HOST_PROOF_OF_ADDRESS]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_OTHER]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        }
    },
    [DOCUMENT_CATEGORY_IDENTITY]: {
        [DOCUMENT_NAME_RESIDENCE_CARD]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: false
        },
        [DOCUMENT_NAME_PASSPORT]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: false
        },
        [DOCUMENT_NAME_DRIVER_LICENSE]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE, DOCUMENT_SLOT_NAME_BACK_SIDE],
            isMultipleAvailable: false
        },
        [DOCUMENT_NAME_ID_CARD]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE, DOCUMENT_SLOT_NAME_BACK_SIDE],
            isMultipleAvailable: false
        },
        [DOCUMENT_NAME_ID_DOCUMENT]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        }
    },
    [DOCUMENT_CATEGORY_OTHER]: {
        [DOCUMENT_NAME_SELFIE_WITH_ID_CARD]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_OTHER]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_SOURCE_OF_WEALTH]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_OPEN_CHECKS]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_RG_SOW]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_RG_OTHERS]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        }
    },
    [DOCUMENT_CATEGORY_PAYMENT_METHOD]: {
        [DOCUMENT_NAME_BANK_CARD]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE, DOCUMENT_SLOT_NAME_BACK_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_BANK_ACCOUNT_IDENTITY]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_SWORN_STATEMENT_BANK_CARD]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_PROOF_OF_PAYMENT_METHOD]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_EWALLET]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        },
        [DOCUMENT_NAME_OTHER]: {
            uploadingSlots: [DOCUMENT_SLOT_NAME_FRONT_SIDE],
            isMultipleAvailable: true
        }
    }
};

export const kycNotifications = {
    [DOCUMENT_STATUS_VALIDATED]: {
        picto: <Success />,
        status: NOTIFICATIONS_STATUS_SUCCESS
    },
    [DOCUMENT_STATUS_REFUSED]: {
        picto: <AlertTriangle />,
        status: NOTIFICATIONS_STATUS_ERROR
    },
    [DOCUMENT_STATUS_EXPIRED]: {
        picto: <AlertTriangle />,
        status: NOTIFICATIONS_STATUS_ERROR
    },
};
