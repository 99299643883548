import handleValidJson from '@tlf-e/brand-utils/dist/helpers/handleValidJson';
import { globalWorkers } from '../../services/http';

const { cmsPageRequest } = globalWorkers;

const GET_SEO_CONTENT = 'GET_SEO_CONTENT';
const GET_SEO_CONTENT_ERROR = 'GET_SEO_CONTENT_ERROR';
const GET_SEO_CONTENT_LOADING = 'GET_SEO_CONTENT_LOADING';

export const initialState = {
    isLoading: false,
    seoContent: {},
    isError: false
};

const seoContent = (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_SEO_CONTENT:
        return {
            ...state,
            seoContent: {
                ...state.seoContent,
                [action.slug]: handleValidJson(action.payload.data.content)
                    ? JSON.parse(action.payload.data.content)
                    : null
            },
            isLoading: false,
            isError: false
        };
    case GET_SEO_CONTENT_ERROR:
        return {
            ...state,
            isLoading: false,
            isError: true
        };
    case GET_SEO_CONTENT_LOADING:
        return {
            ...state,
            isLoading: true,
            isError: false
        };
    default:
        return state;
    }
};

export default seoContent;

export const getSeoContent = (slug, lang) => {
    const success = (payload) => ({
        type: GET_SEO_CONTENT,
        payload,
        slug
    });
    const fail = () => ({
        type: GET_SEO_CONTENT_ERROR
    });
    const loading = () => ({
        type: GET_SEO_CONTENT_LOADING
    });
    return (dispatch) => {
        dispatch(loading());
        cmsPageRequest(lang, slug)
            .then((res) => {
                dispatch(success(res.data));
            })
            .catch(() => dispatch(fail()));
    };
};
