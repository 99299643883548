import StatusModal from './StatusModal';
import { MODAL_STATUS_ERROR } from '../../variables';
import openCustomerSupportWidget from '@tlf-e/brand-utils/dist/helpers/openCustomerSupportWidget';

const ContactSupportModal = ({ isOpen, onClose, content, title, tr }) => {
    const handleContactSupport = () => {
        openCustomerSupportWidget();
        onClose();
    };

    return (
        <StatusModal isOpen={isOpen} status={MODAL_STATUS_ERROR} title={title} onClose={onClose} content={content}>
            <div className="container-btns">
                <button
                    className="btn btn-submit btn-large btn-modern"
                    target="_top"
                    onClick={handleContactSupport}
                    data-testid="open-support-widget"
                >
                    {tr['contact_support']}
                </button>
            </div>
        </StatusModal>
    );
};

export default ContactSupportModal;
