import Link from 'next/link';
import Close from '../svg-icons/Close';
import { imageBaseUrl } from '../../services/http';
import routes from '../../data/routes.json';

const Menu = ({ onClose, children }) => {
    return (
        <div className="menu" data-testid="menu">
            <div className="menu-content">
                <div className="menu--top">
                    <Link href={routes.home}>
                        <a className="logo-container" data-testid="logo_container" onClick={onClose}>
                            <img src={`${imageBaseUrl}/landing/logo.png`} alt="ws_logo" />
                            <img src={`${imageBaseUrl}/landing/text_logo.png`} alt="ws_text_logo" />
                        </a>
                    </Link>
                    <div className="menu--close" role="button" data-testid="menu_close" onClick={onClose}>
                        <Close />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Menu;
