import { useRef } from 'react';
import Script from 'next/script';
import getTomHornGame from '@tlf-e/brand-utils/dist/games/providers/getTomHornGame';
import { useDispatch } from 'react-redux';
import { initGameLaunchContent } from '../../../../store/reducers/games';
import useTomHornGame from '@tlf-e/brand-utils/dist/hooks/useGameProvider/useTomHornGame';

const TomHornGame = ({ gameLaunchContent, isMobile, dimensions }) => {
    const containerIframeRef = useRef();
    const dispatch = useDispatch();
    const gameScript = getTomHornGame(gameLaunchContent, isMobile);
    const initializeGameLaunchContent = () => dispatch(initGameLaunchContent());
    const [gameContainerId] = useTomHornGame(containerIframeRef, initializeGameLaunchContent, dimensions, isMobile);

    return (
        <div id="game" ref={containerIframeRef}>
            {isMobile ? <div id="egamings_container" className="mobile"></div> : <div id="egamings_container"></div>}
            {gameScript && (
                <Script
                    id={gameContainerId}
                    dangerouslySetInnerHTML={{
                        __html: gameScript
                    }}
                />
            )}
        </div>
    );
};

export default TomHornGame;
