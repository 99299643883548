import getCookie from '@tlf-e/brand-utils/dist/helpers/cookieConfig/getCookie';
import setCookie from '@tlf-e/brand-utils/dist/helpers/cookieConfig/setCookie';
import handleValidJson from '@tlf-e/brand-utils/dist/helpers/handleValidJson';
import getCookieDomain from './getCookieDomain';
import { COOKIE_NAME_CLIENT_IP , COOKIE_NAME_LOCATION_IP_INFO } from '../variables';

export const setDomainCookie = (name, value, options = {}) => {
    const domain = getCookieDomain();

    options = {
        domain,
        ...options
    };

    setCookie(name, value, options);
};

export const clearDomainCookie = (name) => {
    setDomainCookie(name, '', {
        'max-age': -1
    });
};

export const getLocationCookie = () => {
    const clientIpCookie = getCookie(COOKIE_NAME_CLIENT_IP);
    const locationObjectCookie = getCookie(COOKIE_NAME_LOCATION_IP_INFO);
    const locationData = handleValidJson(locationObjectCookie)
        ? JSON.parse(locationObjectCookie)
        : null;

    if (clientIpCookie !== locationData?.ip) {
        clearDomainCookie(COOKIE_NAME_LOCATION_IP_INFO);
        return {
            locationObjectCookie: null,
            isValidCookie: false,
        };
    }

    const { county, ip, code_country } = locationData || {};
    return {
        locationObjectCookie,
        isValidCookie: Boolean(county && ip && code_country),
    };
};
