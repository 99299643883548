import router from 'next/router';
import dataLayerPush from '@tlf-e/brand-utils/dist/helpers/dataLayerPush';
import { wheelOfFortuneWorkers } from '../../services/http';
import {
    WHEEL_CONTENT_MODAL,
    WHEEL_CONTENT_PAGE,
    WOF_STANDINGS_FETCH,
    WOF_STANDINGS_REFRESH
} from '../../variables';
import routes from '../../data/routes.json';

const { getWofRequest, getWofStatusRequest, getWofAllStandingsRequest } = wheelOfFortuneWorkers;

const GET_WOF = 'GET_WOF';
const GET_WOF_SUCCESS = 'GET_WOF_SUCCESS';
const GET_WOF_FAILED = 'GET_WOF_FAILED';
const RESET_CONTENT_LOADED = 'RESET_CONTENT_LOADED';
const GET_WOF_STANDINGS = 'GET_WOF_STANDINGS';
const GET_WOF_STANDINGS_SUCCESS = 'GET_WOF_STANDINGS_SUCCESS';
const GET_WOF_STANDINGS_FAILED = 'GET_WOF_STANDINGS_FAILED';
const SET_WHEEL_MODE = 'SET_WHEEL_MODE';
const SET_WHEEL_ORIGIN = 'SET_WHEEL_ORIGIN';
const SET_IS_WOF_ENABLED = 'SET_IS_WOF_ENABLED';
const SET_IS_WIDGET_MODAL_OPENED = 'SET_WIDGET_MODAL_OPENED';
const UPDATE_WOF = 'UPDATE_WOF';
const INIT_WHEEL_STATE = 'INIT_WHEEL_STATE';

export const initialState = {
    isWofEnabled: false,
    isWofStatusLoaded: false,
    wof: null,
    isLoading: false,
    isWidgetWheelEnabled: false,
    isWofPageContentLoaded: false,
    isWofModalContentLoaded: false,
    isWidgetModalOpened: false,
    wofStandings: {
        all: [],
        player: null
    },
    wofStandingsLoadingType: '',
    wheelMode: '',
    wheelOrigin: ''
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_WOF:
        return {
            ...state,
            isLoading: true
        };
    case GET_WOF_SUCCESS:
        return {
            ...state,
            wof: action.payload,
            isLoading: false,
            isLoaded: true,
            isWidgetWheelEnabled: true,
            isWofPageContentLoaded: action.source === WHEEL_CONTENT_PAGE,
            isWofModalContentLoaded: action.source === WHEEL_CONTENT_MODAL,
            wofStandings: handleAllPlayersStandings(action.payload.allStandings)
        };
    case GET_WOF_FAILED:
        return {
            ...state,
            wof: null,
            isLoading: false,
            isLoaded: true,
            isWidgetWheelEnabled: true,
            isWofPageContentLoaded: action.source === WHEEL_CONTENT_PAGE,
            isWofModalContentLoaded: action.source === WHEEL_CONTENT_MODAL,
            wofStandings: {
                all: [],
                player: null
            }
        };
    case GET_WOF_STANDINGS:
        return {
            ...state,
            wofStandingsLoadingType: action.payload
        };
    case GET_WOF_STANDINGS_SUCCESS:
        return {
            ...state,
            wofStandings: action.payload,
            wofStandingsLoadingType: ''
        };
    case GET_WOF_STANDINGS_FAILED:
        return {
            ...state,
            wofStandingsLoadingType: ''
        };
    case RESET_CONTENT_LOADED:
        return {
            ...state,
            isWofPageContentLoaded: false,
            isWofModalContentLoaded: false
        };
    case SET_WHEEL_MODE:
        return {
            ...state,
            wheelMode: action.payload
        };
    case SET_WHEEL_ORIGIN:
        return {
            ...state,
            wheelOrigin: action.payload
        };
    case SET_IS_WOF_ENABLED:
        return {
            ...state,
            isWofEnabled: action.payload,
            isWofStatusLoaded: action.isLoaded
        };
    case SET_IS_WIDGET_MODAL_OPENED:
        return {
            ...state,
            isWidgetModalOpened: action.payload
        };
    case UPDATE_WOF:
        const wof = {
            ...state.wof,
            ...action.payload
        };
        return {
            ...state,
            wof,
            wofStandings: handleAllPlayersStandings(wof.allStandings)
        };
    case INIT_WHEEL_STATE:
        return initialState;
    default:
        return state;
    }
};

export const getWof = (source) => {
    const loading = () => ({
        type: GET_WOF
    });
    const success = (payload) => ({
        type: GET_WOF_SUCCESS,
        payload,
        source
    });
    const fail = () => ({
        type: GET_WOF_FAILED,
        source
    });
    return (dispatch, getState) => {
        dispatch(loading());
        const handleRestrictedStatus = () => {
            source === WHEEL_CONTENT_PAGE && router.push(routes.home);
            dispatch(fail());
        };
        const getWofData = (isEnabled) => {
            if (isEnabled) {
                getWofRequest()
                    .then((res) => {
                        const [wof] = res.data.data;
                        const wofId = wof?.id;
                        if (wofId) {
                            dispatch(success(wof));
                        } else {
                            dispatch(fail());
                        }
                    })
                    .catch(() => dispatch(fail()));
            } else handleRestrictedStatus();
        };
        const { isWofEnabled, isWofStatusLoaded } = getState().wheel_of_fortune;

        // Prevent calling request for status if it's already loaded
        if (isWofStatusLoaded) {
            getWofData(isWofEnabled);
        } else {
            getWofStatusRequest()
                .then((status) => {
                    dispatch(setIsWofEnabled(status.data.status, true));
                    getWofData(status.data.status);
                })
                .catch(() => {
                    handleRestrictedStatus();
                    dispatch(setIsWofEnabled(false, false));
                });
        }
    };
};

const handleAllPlayersStandings = (data) => {
    const { playerStandings: playerStandingsData, standings: allPlayersStandingsData } = data;
    return {
        player: playerStandingsData || null,
        all:
            (playerStandingsData?.position > 6
                ? [...allPlayersStandingsData.slice(0, 5), playerStandingsData]
                : [...allPlayersStandingsData]) || []
    };
};
export const getWofStandings = (id, loadingType = WOF_STANDINGS_FETCH, limit = 6) => {
    const loading = (payload) => ({
        type: GET_WOF_STANDINGS,
        payload
    });
    const success = (payload) => ({
        type: GET_WOF_STANDINGS_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_WOF_STANDINGS_FAILED
    });
    return (dispatch, getState) => {
        dispatch(loading(loadingType));
        getWofAllStandingsRequest(id, { limit })
            .then((allStandings) => {
                const isRefresh = loadingType === WOF_STANDINGS_REFRESH;
                const playerStandingsData = allStandings.data.data.playerStandings;
                if (isRefresh) {
                    const { id: playerId } = getState().user.information;
                    dataLayerPush({
                        event: 'wf_refresh_leaderboard',
                        player_id: playerId,
                        player_current_position: playerStandingsData?.position
                    });
                }
                dispatch(success(handleAllPlayersStandings(allStandings.data.data)));
            })
            .catch(() => dispatch(fail()));
    };
};

export const resetContentLoaded = () => ({
    type: RESET_CONTENT_LOADED
});

export const setWheelMode = (payload) => ({
    type: SET_WHEEL_MODE,
    payload
});

export const setWheelOrigin = (payload) => ({
    type: SET_WHEEL_ORIGIN,
    payload
});

export const setIsWofEnabled = (payload, isLoaded) => ({
    type: SET_IS_WOF_ENABLED,
    payload,
    isLoaded
});

export const setIsWidgetModalOpened = (payload) => ({
    type: SET_IS_WIDGET_MODAL_OPENED,
    payload
});

export const initWheelState = () => ({
    type: INIT_WHEEL_STATE
});

export const updateWof = (payload) => ({
    type: UPDATE_WOF,
    payload
});
