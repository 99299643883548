import { jackpotWorkers } from '../../services/http';
const { jackpotRequest } = jackpotWorkers;

const SET_WAZDAN_GAMES_DATA = 'SET_WAZDAN_GAMES_DATA';
const GET_WAZDAN_INFO_DATA = 'GET_WAZDAN_INFO_DATA';
const GET_WAZDAN_INFO_DATA_SUCCESS = 'GET_WAZDAN_INFO_DATA_SUCCESS';
const GET_WAZDAN_INFO_DATA_FAILED = 'GET_WAZDAN_INFO_DATA_FAILED';

export const initialState = {
    data: {
        gamesIds: [],
        counters: [],
    },
    isCountersLoading: false,
    claimWazdanFailed: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case SET_WAZDAN_GAMES_DATA:
        return {
            ...state,
            data: {
                ...state.data,
                gamesIds: action.payload,
            },
        };
    case GET_WAZDAN_INFO_DATA:
        return {
            ...state,
            isCountersLoading: true,
            claimWazdanFailed: false
        };
    case GET_WAZDAN_INFO_DATA_SUCCESS:
        const counters = action.payload.counters.map((item) => {
            return {
                ...item,
                name: item.name.toLowerCase(),
            };
        }).reverse();

        return {
            ...state,
            data: {
                ...state.data,
                counters,
            },
            isCountersLoading: false
        };
    case GET_WAZDAN_INFO_DATA_FAILED:
        return {
            ...state,
            isCountersLoading: false,
            claimWazdanFailed: true
        };
    default:
        return state;
    }
};

export const getWazdanInfoData = (body) => {
    const loading = () => ({
        type: GET_WAZDAN_INFO_DATA
    });
    const success = (payload) => ({
        type: GET_WAZDAN_INFO_DATA_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_WAZDAN_INFO_DATA_FAILED
    });

    return (dispatch, getState) => {
        const { counters } = getState().jackpot.data;
        !counters.length && dispatch(loading());
        jackpotRequest(body)
            .then((res) => {
                const { data } = res;
                dispatch(success(data[body.promotionIds[0]]));
            })
            .catch(() => {
                dispatch(fail());
            });
    };
};

export const setWazdanGamesData = (payload) => ({
    type: SET_WAZDAN_GAMES_DATA,
    payload
});
